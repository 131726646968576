

































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { LoginSocialViewModel } from '../viewmodel/login-social-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { localData } from '@/stores/local-data'

@Observer
@Component
export default class extends Vue {
  @Provide() vm = new LoginSocialViewModel()

  wallet = walletStore

  googleLogin() {
    localData.setConnectYoutubeFlag(false)
    window.open(`${process.env.VUE_APP_API_ENDPOINT}/connect/google`, '_self')
  }

  appleLogin() {
    window.open(`${process.env.VUE_APP_API_ENDPOINT}/connect/apple`, '_self')
  }

  later() {
    walletStore.disconnect()
  }
}
